<template lang="pug">
.popup-overlay(v-if="visible" @click="closePopup")
  .popup(@click.stop)
    .popup__close(@click="closePopup")
      ui-icon(name="close" color="gray80")
    .popup__header
      ui-icon.mr-3(v-if="successPopup" name="check-l" size=48)
      h2.popup__title {{ title }}
    .popup__content
      slot
    .popup__footer
      slot(name="left")
      .popup__footer-right-block
        ui-btn.mr-2(@click="closePopup" v-if="declainText" secondary) {{ declainText }}
        ui-btn(@click="handleSubmit" v-if="!successPopup" primary :disable="submitDisable")
          ui-icon.mr-3(v-if="withPortalIcon" name="portal-mini-green" color="portal")
          | {{ submitText }}
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    declainText: { type: String, default: '' },
    submitText: { type: String, default: 'Принять' },
    submitDisable: { type: Boolean, default: false },
    successPopup: { type: Boolean, default: false },
    withPortalIcon: { type: Boolean, default: false },
  },
  emits: ['submit'],
  /* eslint-disable-next-line */
  data() {
    return {
      visible: false,
    };
  },

  methods: {
    /**
     * Открывает всплывающее окно и добавляет обработчик события нажатия клавиши Escape.
     */
    openPopup() {
      this.visible = true;
      document.body.style.overflow = 'hidden';
      window.addEventListener('keydown', this.handleEscKey);
    },

    /**
     * Закрывает всплывающее окно и удаляет обработчик события нажатия клавиши Escape.
     */
    closePopup() {
      this.visible = false;
      document.body.style.overflow = 'visible';
      window.removeEventListener('keydown', this.handleEscKey);
    },

    /**
     * Обрабатывает событие нажатия клавиши Escape.
     * @param {Event} event - Объект события клавиши.
     */
    handleEscKey(event) {
      if (event.key === 'Escape') {
        this.closePopup();
      }
    },

    /**
     * Обрабатывает событие отправки формы и вызывает событие "submit".
     */
    handleSubmit() {
      document.body.style.overflow = 'visible';
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss">
.popup {
  width: 640px;
  min-height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  color: $gray100;
  padding: 42px 48px 40px 48px;
  border-radius: 16px;
  box-shadow: 0px 58px 60px 0px rgba(34, 44, 54, 0.35);

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(93, 115, 132, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: 14px;
    right: 14px;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
  }
  &__title {
    font-size: 32px;
    max-width: 438px;
    line-height: 1.2;

    &::first-letter {
      text-transform: capitalize;
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;

    &-right-block {
      display: flex;
      margin-left: auto;
    }
  }
}

// MEDIA 320-600
@media screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
  .popup {
    width: 100%;
    min-height: auto;
    margin-top: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 36px 16px 40px;

    &__title {
      font-size: 26px;
      width: 100%;
    }
    &__footer {
      &-right-block {
        flex-direction: column-reverse;
        margin: 30px 0 0;
        width: 100%;

        button:nth-child(1) {
          margin-bottom: 10px;
          margin-right: 0;
        }
        button:last-child {
          margin-bottom: 0;
          margin-right: 0;
        }
        .btn-secondary {
          display: none;
        }
      }
    }

    .closable-only & {
      .btn-primary {
        display: none;
      }
      &__footer-right-block .btn-secondary {
        display: flex;
      }
    }
  }
}
</style>
